var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      _c("virtual-phone-masthead", {
        attrs: { title: "Settings for" },
        on: { loaded: _vm.loadedPhoneMasthead, change: _vm.resetAfterChange },
      }),
      _vm.loaded
        ? _c(
            "b-tabs",
            { attrs: { card: "", "content-class": "mt-3" } },
            [
              _c(
                "b-tab",
                { attrs: { title: "Phone Settings", active: "" } },
                [
                  _c("virtual-phone-phone-settings", {
                    key: _vm.selectedPhone.id,
                  }),
                ],
                1
              ),
              _c(
                "b-tab",
                { attrs: { title: "Voicemail Settings" } },
                [
                  _c("virtual-phone-custom-voicemail-settings", {
                    key: _vm.selectedPhone.id,
                  }),
                ],
                1
              ),
              _c(
                "b-tab",
                { attrs: { title: "Caller ID Settings" } },
                [
                  _c("virtual-phone-caller-id-settings", {
                    key: _vm.selectedPhone.id,
                  }),
                ],
                1
              ),
              _c(
                "b-tab",
                { attrs: { title: "E911 Settings" } },
                [
                  _c("virtual-phone-e911-settings", {
                    key: _vm.selectedPhone.id,
                  }),
                ],
                1
              ),
              _c(
                "b-tab",
                { attrs: { title: "Mobile App Access" } },
                [_c("mobile-access")],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.arePhonesActive
        ? _c("div", [
            _c("p", [_vm._v("There are currently no active phones.")]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }