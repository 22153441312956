var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "e911-confirm-modal",
          attrs: { id: "e911-confirm-modal", title: "Important" },
          on: { ok: _vm.handleOk },
        },
        [
          _vm._v(
            "\n    When you enable E911, your location will be communicated to the local emergency dispatch in your area. Please provide your current location to the operator, in addition to the address provided by the system to assist with the most accurate and timely response. For more information, please read our "
          ),
          _c(
            "a",
            {
              attrs: {
                href: "https://www.northwestregisteredagent.com/terms-policy/voip-services",
              },
            },
            [_vm._v("Terms of Service")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }