<template>
  <div>
    <div>
      <b-card class="mt-2">
        <div class="toggle-button">
          <div>
            <span>Enable E911 Service</span>
            <fa-icon v-b-popover.hover.top="e911ToggleToolTip" class="question-circle pl-1 mr-2" icon="question-circle" size="1x" />
            <span
              :class="isToggleDisabled ? 'toggle-wrapper toggle-is-disabled' : 'toggle-wrapper'"
              role="checkbox"
              :aria-checked="e911Choice.toString()"
              tabindex="0"
              @click="toggle911"
              @keydown.space.prevent="toggle911"
            >
              <span
                class="toggle-background"
                :class="backgroundStyles"
              />
              <span
                class="toggle-indicator"
                :style="indicatorStyles"
              />
            </span>
          </div>
        </div>

        <div v-show="e911Choice" class="mt-4">
          <b-form-row>
            <b-form-group class="col-12">
              <label>Caller Name</label>
              <b-input v-model="callerName" max-length="50" placeholder="First Last" />
            </b-form-group>
          </b-form-row>

          <b-form-row>
            <b-form-group class="col-md-8">
              <label>House Number</label>
              <b-input v-model="houseNumber" type="number" max="6" step="1" placeholder="1234" />
            </b-form-group>
            <b-form-group class="col-md-4">
              <label>House Suffix</label>
              <b-input v-model="houseSuffix" max-length="45" placeholder="a" />
            </b-form-group>
          </b-form-row>

          <b-form-row>
            <b-form-group class="col-md-2">
              <label>Direction</label>
              <b-input v-model="preDirectional" max-length="2" placeholder="N" />
            </b-form-group>
            <b-form-group class="col-md-6">
              <label>Street Name</label>
              <b-input v-model="streetName" max-length="200" placeholder="Adams" />
            </b-form-group>
            <b-form-group class="col-md-2">
              <label>Street Suffix</label>
              <b-input v-model="streetSuffix" max-length="45" placeholder="AVE" />
            </b-form-group>
            <b-form-group class="col-md-2">
              <label>Direction</label>
              <b-input v-model="postDirectional" max-length="2" placeholder="N" />
            </b-form-group>
          </b-form-row>

          <b-form-row>
            <b-form-group class="col-12">
              <label>Address Line 2</label>
              <b-input v-model="addressLine2" max-length="200" placeholder="Suite 100" />
            </b-form-group>
          </b-form-row>

          <b-form-row>
            <b-form-group class="col-md-6">
              <label>City</label>
              <b-input v-model="city" max-length="100" placeholder="Montgomery" />
            </b-form-group>
            <b-form-group class="col-md-2">
              <label>State</label>
              <b-input v-model="stateCode" max-length="2" placeholder="AL" />
            </b-form-group>
            <b-form-group class="col-md-4">
              <label>Zip Code</label>
              <b-input v-model="zip" max-length="10" placeholder="36104" />
            </b-form-group>
          </b-form-row>
        </div>
      </b-card>

      <b-button
        v-show="!e911Enabled"
        v-b-modal.e911-confirm-modal
        class="btn-primary button-settings add-address"
        :disabled="submitting || !e911Choice"
      >
        <b-spinner v-show="submitting" small variant="light" label="Spinning" />
        <span v-show="!submitting">Save Settings</span>
      </b-button>

      <b-button
        v-show="e911Enabled"
        class="btn-danger button-settings remove-address"
        :disabled="submitting"
        @click="deleteSettings"
      >
        <b-spinner v-show="submitting" small variant="light" label="Spinning" />
        <span v-show="!submitting">Disable E911</span>
      </b-button>
    </div>
    <virtual-phone-e911-confirm-modal @accepted="saveSettings" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import store from "@/store"
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import VirtualPhoneE911ConfirmModal from "@/components/VirtualPhones/VirtualPhoneE911ConfirmModal"

export default {
  name: "VirtualPhoneE911Settings",
  components: {
    VirtualPhoneE911ConfirmModal,
  },
  data(){
    return {
      submitting: false,
      e911Enabled: false,
      e911Choice: false,
      isToggleDisabled: false,
      virtualPhoneId: '',
      identifier: '',
      callbackNumber: '',
      callerName: '',
      houseNumber: '',
      houseSuffix: '',
      preDirectional: '',
      streetName: '',
      streetSuffix: '',
      postDirectional: '',
      addressLine2: '',
      city: '',
      stateCode: '',
      zip: '',
    }
  },

  computed: {
    ...mapGetters('virtualPhones', [
      'selectedPhone',
      'e911Identifier',
      'e911CallbackNumber',
      'e911CallerName',
      'e911LocationId',
      'e911HouseNumber',
      'e911HouseSuffix',
      'e911PreDirectional',
      'e911StreetName',
      'e911StreetSuffix',
      'e911PostDirectional',
      'e911AddressLine2',
      'e911City',
      'e911StateCode',
      'e911Zip',
    ]),
    backgroundStyles() {
      return {
        'toggle-on': this.e911Choice,
        'toggle-off': !this.e911Choice,
      }
    },
    indicatorStyles() {
      return { transform: this.e911Choice ? 'translateX(14px)' : 'translateX(0)' }
    },
  },

  beforeCreate () {
    this.$store = store
  },

  async mounted() {
    await this.fetchData()
  },

  methods: {
    ...mapActions('virtualPhones', ['fetchE911Info', 'setE911Settings', 'deleteE911Settings']),

    e911ToggleToolTip() {
      return 'This will add 911 functionality to your service. We use the address provided to ' +
        'help route emergency personnel to your location. You are REQUIRED to enter an address ' +
        'to enable 911 functionality.'
    },

    async fetchData () {
      await this.fetchE911Info(this.selectedPhone.id)
      this.virtualPhoneId = this.selectedPhone.id
      this.identifier = this.selectedPhone.number.replace(/^\+/, '')
      this.callbackNumber = this.selectedPhone.number.replace(/^\+1/, '')
      this.callerName = this.e911CallerName
      this.houseNumber = this.e911HouseNumber
      this.houseSuffix = this.e911HouseSuffix
      this.preDirectional = this.e911PreDirectional
      this.streetName = this.e911StreetName
      this.streetSuffix = this.e911StreetSuffix
      this.postDirectional = this.e911PostDirectional
      this.addressLine2 = this.e911AddressLine2
      this.city = this.e911City
      this.stateCode = this.e911StateCode
      this.zip = this.e911Zip

      this.e911Enabled = !!this.e911StreetName
      this.e911Choice = this.e911Enabled
      this.isToggleDisabled = this.e911Enabled
    },

    async saveSettings () {
      this.submitting = true
      const success = await this.setE911Settings({ e911Settings: {
        identifier: this.identifier,
        callback_number: this.callbackNumber,
        caller_name: this.callerName,
        house_number: this.houseNumber,
        house_suffix: this.houseSuffix,
        pre_directional: this.preDirectional,
        street_name: this.streetName,
        street_suffix: this.streetSuffix,
        post_directional: this.postDirectional,
        address_line_2: this.addressLine2,
        city: this.city,
        state_code: this.stateCode,
        zip: this.zip,
      }, vm: this })

      if (success) {
        await this.fetchData()
      }
      this.submitting = false
    },

    async deleteSettings () {
      this.submitting = true
      await this.deleteE911Settings({ identifier: this.e911Identifier, locationId: this.e911LocationId, vm: this })
      await this.fetchData()
      this.submitting = false
    },

    toggle911 () {
      this.e911Choice = !this.e911Choice
    },
  },
}
</script>

<style lang="scss" scoped>
.toggle-button {
  .toggle-on {
    background-color: rgb(95,188,186);
  }

  .toggle-off {
    background-color: #c2c2c2;
  }

  .toggle-is-disabled {
    height: 0;
    overflow: visible;
    pointer-events: none;
    background:none !important;
    opacity: 0.6;
  }

  .toggle-wrapper {
    display: inline-block;
    position: relative;
    cursor: pointer;
    width: 32px;
    height: 18px;
    border-radius: 9999px;
    transform: scale(1.6);
    transform-origin: center;
    vertical-align: middle;
    margin: 0 0 3px 4rem;
  }

  .toggle-wrapper:focus {
    outline: 0;
  }

  .toggle-background {
    display: inline-block;
    border-radius: 9999px;
    height: 100%;
    width: 100%;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color .4s ease;
  }

  .toggle-indicator {
    position: absolute;
    height: 14px;
    width: 14px;
    left: 2px;
    bottom: 2px;
    background-color: #ffffff;
    border-radius: 9999px;
    box-shadow:  0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform .4s ease;
  }

  p {
    display: inline;
    font-size: .75rem;
  }
}

.button-settings {
  margin-top: 1rem;
  &.add-address {
    width: 10.5rem;
  }
  &.remove-address {
    width: 10rem;
  }
}

fieldset {
  input::placeholder {
    color: #cbcbcb;
  }
}

</style>
