<template>
  <div>
    <b-modal
      id="e911-confirm-modal"
      ref="e911-confirm-modal"
      title="Important"
      @ok="handleOk"
    >
      When you enable E911, your location will be communicated to the local emergency dispatch in your area. Please provide your current location to the operator, in addition to the address provided by the system to assist with the most accurate and timely response. For more information, please read our <a href="https://www.northwestregisteredagent.com/terms-policy/voip-services">Terms of Service</a>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'VirtualPhoneE911ConfirmModal',
  methods: {
    async handleOk() {
      this.$emit('accepted')
    },
  },
}
</script>
